import { ElMessage } from "element-plus";
import router from "../router/index"; 
// 导入axios
import axios from "axios";
// 使用vant-ui 做消息提醒
// axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";

//1. 创建新的axios实例，
const request = axios.create({
  // 公共接口
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 20 * 1000,
});
// 2.请求拦截器
request.interceptors.request.use(
  (config) => {
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    //如有需要：注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    const token = localStorage.getItem("token") || "";
    config.headers["token"] = token; //如果要求携带在请求头中
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function getErrorMessage(status) {
  let message = "";
  switch (status) {
    case 400:
      message = "错误请求";
      break;
    case 401:
      message = "登录状态已过期，请重新登录";
      break;
    case 403:
      message = "拒绝访问";
      break;
    case 404:
      message = "请求错误,未找到该资源";
      // window.location.href = "/NotFound"
      break;
    case 405:
      message = "请求方法未允许";
      break;
    case 408:
      message = "请求超时";
      break;
    case 500:
      message = "服务器端出错";
      break;
    case 501:
      message = "网络未实现";
      break;
    case 502:
      message = "网络错误";
      break;
    case 503:
      message = "服务不可用";
      break;
    case 504:
      message = "网络超时";
      break;
    case 505:
      message = "http版本不支持该请求";
      break;
    default:
      message = `连接错误${error.response.status}`;
  }
  return message;
}
// 3.响应拦截器
request.interceptors.response.use(
  (response) => {
      //接收到响应数据并成功后的一些共有的处理，关闭loading等
    const { code, message } = response.data;
      if (code=== 401) {
      router.replace({
        path: "/login",
      });
    }else if (code !== 200) {
      ElMessage.error(message);
      return Promise.reject(response);
    }
    return response.data;
  },
  (error) => {
    console.log("连接服务器失败");
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      error.message = getErrorMessage(error.response.status);
      if (error.response.status === 401) {
        router.replace({
          path: "/login",
        });
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        error.message = "服务器响应超时，请刷新当前页";
      }
    }
    ElMessage.error(error.message);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.reject(error.response);
  }
);

//4.导入文件
export default request;
