import JSEncrypt from "jsencrypt";

const publicKey =
  "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtHYK6vzmpvC1gAdt3gQm\n" +
  "dGCsvJbB6V7hnKMFeGp+t+2OhUHz9THHbpCGCpBqcG040w/4RNwWiSrw7W/DDnvj\n" +
  "VPdnMRsiURhsRJHhVOZ8qJ9ViMb7C60xE2OmtnoSn4ERSSib6ydPgEyY2LasUVfD\n" +
  "gT8P1fdAI0u9c0oMTxD9g7VZ0Us0SIA8t2HozedITPspMbWk1EIIdVum+Gw9Kr5Q\n" +
  "hYKrnl4FgcjM8eZNnYSGFlbc0P3+bv4CkJsZe9xB1wL+Qkq2b+29VyMgSVkvGXKl\n" +
  "H+FrVkAqqFXC0ZBQDNPnu1aXOoX33CKl3/H6cA+MEw9Pa+jK2/5Z8ZibpLchzHQq\n" +
  "EwIDAQAB";
export const getEncryptPassword = (password) => {
  // 创建加密对象
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  // 加密密码
  const encryptedPassword = encryptor.encrypt(password);
  return encryptedPassword;
};
